/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";
import PropTypes from "prop-types";

export default class Video extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };
  }

  toggleVideo = () => {
    if (this.state.loaded) {
      this.setState({ loaded: false });
      this.video.pause();
      this.props.onToggle(false);
    } else {
      this.setState({ loaded: true });
      this.video.play();
      this.props.onToggle(true);
    }
  };

  render() {
    const { loaded } = this.state;
    const { url, title, poster } = this.props;

    return (
      <div className={`video${loaded ? " loaded" : ""}`}>
        <div
          className="poster"
          role="button"
          tabIndex="0"
          onClick={this.toggleVideo}
          style={{ backgroundImage: `url(${poster})` }}
        >
          <button className="play">
            <img
              src="/static/images/play-button.png"
              alt="Play"
              className="img-responsive center-block"
            />
          </button>
        </div>
        <video
          ref={(elem) => {
            this.video = elem;
          }}
          preload="none"
          playsInline
          controls
          title={title}
          width="100%"
          height="100%"
          poster={poster}
          onEnded={this.toggleVideo}
        >
          <source src={url} type="video/mp4" />
        </video>
      </div>
    );
  }
}

Video.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired,
  onToggle: PropTypes.func,
};

Video.defaultProps = {
  onToggle: () => null,
};
