import React from "react";
import PropTypes from "prop-types";
import Slick from "react-slick";

export default class Slider extends React.Component {
  setSlide(index) {
    this.slick.slickGoTo(index);
  }

  changeSlide(interval) {
    switch (interval) {
      case -1: {
        this.slick.slickPrev();
        break;
      }
      case 1: {
        this.slick.slickNext();
        break;
      }
      default: {
        this.slick.slickGoTo(interval);
        break;
      }
    }
  }

  render() {
    const {
      vertical,
      children,
      className,
      perSlide,
      onSlideChange,
      draggable,
      infinite,
      fade,
      speed,
      autoplay,
      cssEase,
    } = this.props;
    const items = React.Children.toArray(children);
    const settings = {
      autoplay,
      initialSlide: 0,
      infinite: items.length >= perSlide && infinite,
      slidesToScroll: 1,
      vertical,
      adaptiveHeight: perSlide === 1 && vertical, // only use adaptive height in vertical mode
      dots: false,
      swipe: !vertical, // disable swiping in vertical mode to not interfere with scrolling
      draggable,
      slidesToShow: perSlide,
      afterChange: onSlideChange,
      speed,
      cssEase,
      fade: perSlide === 1 && fade,
    };
    return (
      <div
        className={`slider ${className}${
          items.length < perSlide ? "center" : ""
        }`}
      >
        {items.length > 0 && (
          <Slick
            ref={(slick) => {
              this.slick = slick;
            }}
            {...settings}
          >
            {items.map((item) => (
              <div key={item} className="slider__slide">
                {item}
              </div>
            ))}
          </Slick>
        )}
      </div>
    );
  }
}
Slider.propTypes = {
  className: PropTypes.string,
  onSlideChange: PropTypes.func,
  children: PropTypes.node,
  perSlide: PropTypes.number,
  vertical: PropTypes.bool,
  draggable: PropTypes.bool,
  infinite: PropTypes.bool,
  autoplay: PropTypes.bool,
  speed: PropTypes.number,
  fade: PropTypes.bool,
  cssEase: PropTypes.string,
};
Slider.defaultProps = {
  className: "",
  onSlideChange: () => null,
  children: null,
  perSlide: 1,
  vertical: false,
  draggable: false,
  infinite: false,
  autoplay: false,
  fade: false,
  speed: 500,
  cssEase: "ease",
};
