import React from "react";
import SVG from "components/elements/svg";

const Integrations = () => (
  <section className="integrations">
    <div className="container">
      <div className="integrations__text">
        <p>We also market your property on these top UK websites:</p>
      </div>
      <div className="integrations__websites">
        <SVG
          src="rightmove"
          className="integrations__icon integrations__icon__rightmove"
        />
        <SVG
          src="zoopla"
          className="integrations__icon integrations__icon__zoopla"
        />
        <SVG
          src="primelocation"
          className="integrations__icon integrations__icon__primelocation"
        />
      </div>
    </div>
  </section>
);

export default Integrations;
